<template>
	<div>
		<v-tooltip bottom>
			<template v-slot:activator="{ on, attrs }">
				<v-btn :class="buttonClass" text v-bind="attrs" v-on="on">
					{{ buttonText }}<span :class="iconClass"></span>
				</v-btn>
			</template>
			<span class="tooltip-text">{{ tooltipText }}</span>
		</v-tooltip>
	</div>
</template>
<script>
export default {
	props: {
		isPublished: {
			type: Boolean,
			required: false,
			default: false,
		},
	},
	computed: {
		buttonClass() {
			if (!this.isPublished) {
				return ['restore-btn', 'mr-4', 'publish']
			} else {
				return ['restore-btn', 'mr-4', 'unpublish']
			}
		},
		iconClass() {
			if (!this.isPublished) {
				return ['mdi', 'mdi-arrow-collapse-up', 'pl-2']
			} else {
				return ['mdi', 'mdi-arrow-collapse-down', 'pl-2']
			}
		},
		buttonText() {
			return !this.isPublished ? 'Publish' : 'Unpublish'
		},
		tooltipText() {
			return !this.isPublished ? 'Live on Website' : 'Remove form Website'
		},
	},
}
</script>
<style scoped>
.restore-btn {
	padding: 0 16px !important;
}
.publish {
	color: #4caf50;
}
.publish:hover {
	background-color: #e8f5e9;
}
.unpublish {
	color: #e65100 !important;
	border-radius: 7px !important;
	padding: 17px !important;
}
.unpublish:hover {
	background-color: #ffe0b2;
}
</style>
