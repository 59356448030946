<template>
	<div>
		<v-tooltip bottom>
			<template v-slot:activator="{ on, attrs }">
				<v-btn
					v-bind="attrs"
					v-on="on"
					color="#f44336"
					class="trash-btn"
					text
					:loading="deleting"
					@click="askConfirmation()"
				>
					Delete<span class="mdi mdi-delete pl-2 icon"></span>
				</v-btn>
			</template>
			<span class="tooltip-text">Delete Permanently</span>
		</v-tooltip>
		<v-dialog v-model="showModal" max-width="600px">
			<v-card class="pa-6 mb-0 d-flex flex-column delete-modal">
				<div class="d-flex flex-row align-center">
					<span class="mdi mdi-alert-circle icon mr-2"></span>
					<h1 class="heading">{{ confirmationText }}</h1>
				</div>
				<div class="d-flex flex-row justify-end">
					<v-btn
						class="mt-4 mr-4"
						outlined
						color="#721c24"
						@click="confirmDelete()"
						>Delete</v-btn
					>
					<v-btn
						class="mt-4"
						outlined
						color="indigo"
						@click="cancel()"
						>Cancel</v-btn
					>
				</div>
			</v-card>
		</v-dialog>
	</div>
</template>
<script>
export default {
	props: {
		confirmationText: {
			type: String,
			default: 'Are you sure you want to delete?',
		},
		deleting: {
			type: Boolean,
			default: false,
		},
		buttonText: {
			type: String,
			default: 'Delete',
		},
	},
	data() {
		return {
			showModal: false,
		}
	},
	methods: {
		askConfirmation() {
			this.showModal = true
		},
		confirmDelete() {
			this.$emit('confirm')
			this.showModal = false
		},
		cancel() {
			this.showModal = false
		},
	},
}
</script>
<style scoped>
.trash-btn {
	padding: 0 16px !important;
}
.trash-btn:hover {
	background-color: #ffebee;
}
</style>
