<template>
	<v-container v-if="$can('view', 'Testimony')" fluid>
		<div v-if="$can('create', 'Testimony')" class="text-right">
			<v-btn
				rounded
				color="primary"
				dark
				@click=";(openModal = true), clearState()"
			>
				+ Add Testimonial
			</v-btn>
		</div>

		<v-row v-if="testimonies.length > 0" class="mb-4 mt-8">
			<v-col
				cols="6"
				v-for="testimony in testimonies"
				:key="testimony.id"
				class="py-0 d-flex"
			>
				<v-card class="d-flex flex-column card-outter">
					<v-card-text class="text--primary px-6 py-6 mb-3">
						<div class="text-left author mb-2">
							<div
								class="
									d-flex
									justify-space-between
									align-center
									mb-1
								"
							>
								<p
									class="author-name"
									v-if="!testimony.anonimity"
								>
									{{ testimony.givenBy }}
								</p>
								<p class="author-name" v-else>Anonymous</p>
								<v-rating
									v-model="testimony.starRating"
									background-color="grey lighten-1"
									color="warning"
									hover
								></v-rating>
							</div>

							<span class="date">
								{{ testimony.date }}
							</span>
						</div>

						<div class="content">
							{{ testimony.body }}
						</div>

						<div class="mt-3"></div>

						<div class="services mt-1">
							<span class="label"> Services: </span>
							<div v-if="testimony.services.length > 0">
								<v-chip
									class="specialization"
									v-for="service in testimony.services"
									:key="service.id"
									>{{ service.name }}</v-chip
								>
							</div>
						</div>

						<div class="status mt-2 d-flex flex-row">
							<span class="label"> Status: </span>
							<StatusChips
								:is-published="testimony.isPublished"
							></StatusChips>
						</div>
					</v-card-text>

					<div
						v-if="
							$can('update', 'Testimony') ||
							$can('publish', 'Testimony') ||
							$can('delete', 'Testimony')
						"
						class="card-actions"
					>
						<v-divider></v-divider>
						<v-card-actions
							class="
								px-6
								py-4
								d-flex
								flex-row
								justify-center
								align-center
							"
						>
							<UnoEditButton
								v-if="$can('update', 'Testimony')"
								@click.native="editTestimony(testimony.id)"
							></UnoEditButton>

							<UnoPublishButton
								v-if="$can('publish', 'Testimony')"
								:is-published="testimony.isPublished"
								@click.native="toggleStatus(testimony)"
							></UnoPublishButton>

							<v-spacer></v-spacer>

							<UnoDeleteButton
								v-if="$can('delete', 'Testimony')"
								@confirm="deleteTestimony(testimony.id)"
							></UnoDeleteButton>
						</v-card-actions>
					</div>
				</v-card>
			</v-col>
		</v-row>

		<NoData v-else></NoData>

		<Paginator
			:paginator="paginator"
			:url="getUrl()"
			@update="changePage($event)"
		></Paginator>

		<v-row v-if="openModal" class="justify-center d-flex align-center">
			<CreateTestimony
				@click="openModal = false"
				@closeModal="closeModal"
			/>
		</v-row>
	</v-container>
</template>

<script>
import Paginator from '../../components/Paginator'
import NoData from '../../components/NoData'
import CreateTestimony from './CreateTestimony'
// ACTION BUTTONS
import UnoPublishButton from '@/components/Buttons/UnoPublishButton'
import UnoEditButton from '@/components/Buttons/UnoEditButton'
import UnoDeleteButton from '@/components/Buttons/UnoDeleteButton'
import StatusChips from '@/components/StatusChips'
export default {
	components: {
		NoData,
		UnoEditButton,
		UnoPublishButton,
		UnoDeleteButton,
		StatusChips,
		CreateTestimony,
		Paginator,
	},
	props: {
		openSideBar: {
			type: Boolean,
		},
	},
	data() {
		return {
			openModal: false,
			initialData: {},
		}
	},
	created() {
		this.$store.dispatch('testimony/getListingData')
	},
	computed: {
		testimonies: {
			get() {
				return this.$store.state.testimony.testimonies
			},
			set(value) {
				this.updateTestimonies(value)
			},
		},
		paginator: {
			get() {
				return this.$store.state.testimony.paginator
			},
			set(value) {
				this.updatePaginator(value)
			},
		},
		errors: {
			get() {
				return this.$store.state.testimony.errors
			},
		},
		recentlyAdded: {
			get() {
				return this.$store.state.testimony.recentlyAdded
			},
		},
	},
	methods: {
		updatePaginator(value) {
			this.$store.commit('testimony/updatePaginator', value)
		},
		updateTestimonies(value) {
			this.$store.commit('testimony/updateTestimonies', value)
		},
		getUrl() {
			const projectId = this.$store.state.app.projectId
			let url = `/${projectId}/testimonies`
			return url
		},
		changePage(data) {
			this.testimonies = data.data
			this.paginator = data.paginator
		},
		closeModal() {
			this.openModal = !this.openModal
		},
		clearState() {
			this.$store.commit('testimony/clearState')
		},
		async editTestimony(testimonyId) {
			await this.$store.dispatch('testimony/getInitialData', {
				id: testimonyId,
			})
			this.openModal = true
		},
		deleteTestimony(id) {
			this.$store.dispatch('testimony/deleteTestimony', {
				testimonyId: id,
			})
			this.$store.dispatch('testimony/getListingData', {
				root: true,
			})
		},
		toggleStatus(testimony) {
			const projectId = this.$store.state.app.projectId
			this.axios({
				method: 'post',
				url: `/${projectId}/testimonies/toggle-status/${testimony.id}`,
			}).then((res) => {
				testimony.isPublished = res.data.data.isPublished
			})
		},
	},
}
</script>

<style scoped>
.publish {
	color: white !important;
	background-color: #81c784 !important;
	border-radius: 12px !important;
	padding: 5px 8px !important;
}
.service {
	border-radius: 12px !important;
	padding: 5px 8px !important;
}
.author-name {
	font-size: 1.1rem;
	line-height: 1.4;
}
.date {
	color: #808080eb !important;
}
.v-btn {
	color: white;
	font-size: 14px;
	text-align: center;
}
.restore-btn:hover {
	background-color: white !important;
}
.label {
	font-size: 1rem;
	color: #2b6dc2;
}
.v-chip {
	height: 28px !important;
	line-height: 28px !important;
	background-color: whitesmoke;
	margin: 0px 0px 5px 5px !important;
}
.content {
	display: -webkit-box;
	-webkit-line-clamp: 3;
	-webkit-box-orient: vertical;
	overflow: hidden;
}
.v-card {
	width: 100%;
	box-shadow: none !important;
	border: 1px solid gainsboro !important;
}
.trash-btn {
	padding: 0px 4px 0px 4px !important;
}
.restore-btn {
	padding: 0px 4px 0px 4px !important;
}
.card-outter {
	position: relative;
	padding-bottom: 50px;
}
.card-actions {
	position: absolute;
	bottom: 0;
	width: 100%;
}
.v-card {
	width: 100% !important;
}
::v-deep .v-icon {
	padding: 3px !important;
}
</style>
