<template>
	<v-row align="center" justify="center">
		<v-col align="center" justify="center">
			<v-img
				src="@/assets/img/common/empty-state.png"
				max-width="300"
				class="mt-4"
			/>

			<p class="text-h5 grey--text">
				{{ title }}
			</p>

			<p
				v-if="subtitle"
				class="subtitle text-subtitle-2 mt-4 grey--text text--lighten-1"
			>
				{{ subtitle }}
			</p>
		</v-col>
	</v-row>
</template>

<script>
export default {
	props: {
		title: {
			type: String,
			default: 'Oops! nothing here, yet.',
		},
		subtitle: {
			type: String,
		},
	},
}
</script>

<style scoped>
.subtitle {
	max-width: 300px;
}
</style>
