var render = function render(){var _vm=this,_c=_vm._self._c;return (_vm.paginator.lastPage > 1)?_c('v-row',{staticClass:"mx-0"},[_c('v-col',{staticClass:"pages",attrs:{"cols":"9"}},[_c('ul',{staticClass:"pagination pa-0"},[_c('li',{class:[
					'page',
					_vm.paginator.currentPage == 1 ? 'disabled' : '',
				],on:{"click":function($event){_vm.paginator.currentPage !== 1
						? _vm.debouncedChangePage({ page: 1 })
						: null}}},[_c('a',[_vm._v("First")])]),_c('li',{class:{ disabled: _vm.paginator.currentPage === 1 },on:{"click":function($event){_vm.paginator.currentPage !== 1
						? _vm.debouncedChangePage({
								page: _vm.paginator.currentPage - 1,
						  })
						: null}}},[_c('span',{staticClass:"mdi mdi-arrow-left-bold-circle",staticStyle:{"font-size":"30px","color":"var(--blue)","cursor":"pointer"}})]),_vm._l((_vm.pagesArr),function(pageNumber,index){return _c('li',{key:index,class:[
					_vm.paginator.currentPage === pageNumber ? 'active' : '',
					'page',
				],on:{"click":function($event){return _vm.debouncedChangePage({ page: pageNumber })}}},[_c('a',[_vm._v(_vm._s(pageNumber))])])}),_c('li',{class:[
					_vm.paginator.currentPage === _vm.paginator.lastPage
						? 'disabled'
						: '',
				],on:{"click":function($event){_vm.paginator.currentPage !== _vm.paginator.lastPage
						? _vm.debouncedChangePage({
								page: _vm.paginator.currentPage + 1,
						  })
						: null}}},[_c('span',{staticClass:"mdi mdi-arrow-right-bold-circle",staticStyle:{"font-size":"30px","color":"var(--blue)","cursor":"pointer"}})]),_c('li',{class:[
					_vm.paginator.currentPage === _vm.paginator.lastPage
						? 'disabled'
						: '',
					'page',
				],on:{"click":function($event){_vm.paginator.currentPage !== _vm.paginator.lastPage
						? _vm.debouncedChangePage({ page: _vm.paginator.lastPage })
						: null}}},[_c('a',[_vm._v(" Last ")])])],2)]),_c('v-col',{staticClass:"total",attrs:{"cols":"2"}},[_vm._v(" Page "),_c('input',{directives:[{name:"model",rawName:"v-model",value:(_vm.currentPage),expression:"currentPage"}],staticClass:"text-center",domProps:{"value":(_vm.currentPage)},on:{"keyup":function($event){if(!$event.type.indexOf('key')&&_vm._k($event.keyCode,"enter",13,$event.key,"Enter"))return null;return _vm.debouncedChangePage({ page: _vm.currentPage })},"input":function($event){if($event.target.composing)return;_vm.currentPage=$event.target.value}}}),_vm._v(" of "+_vm._s(_vm.paginator.lastPage)+" ")])],1):_vm._e()
}
var staticRenderFns = []

export { render, staticRenderFns }