<template>
	<v-btn @click="navigate()" depressed class="save-btn mr-4 pa-3">
		Edit
		<span class="mdi mdi-pencil pl-2"></span>
	</v-btn>
</template>
<script>
export default {
	props: {
		to: {
			type: [String, Object],
		},
	},
	methods: {
		navigate() {
			if (this.to) {
				this.$router.push(this.to)
			}
		},
	},
}
</script>
<style scoped>
.save-btn {
	color: white !important;
	background-color: var(--blue) !important;
	padding: 0 16px !important;
}
</style>
