<template>
	<v-dialog
		v-if="$can('create', 'Testimony') || $can('update', 'Testimony')"
		justify="center"
		v-model="dialog"
		persistent
		max-width="800px"
	>
		<v-card class="mb-0">
			<div class="d-flex justify-space-between align-start px-6 pt-6">
				<h1 class="font-weight-light crud-title mb-5">Add Testimony</h1>
				<span
					class="mdi mdi-close close"
					@click="resetValidation()"
				></span>
			</div>

			<v-col cols="12" class="px-6 py-0">
				<v-row class="d-flex align-center">
					<v-col cols="6" class="pa-0">
						<v-text-field
							label="Enter name"
							solo
							flat
							:disabled="anonimity ? true : false"
							outlined
							v-model="givenBy"
							hint="The name above will be displayed on the website as the writter of the testimonial"
							:error-messages="errors.givenBy"
						></v-text-field>
					</v-col>

					<v-col cols="6" class="py-0 pr-0">
						<v-menu
							v-model="fromDateMenu"
							:close-on-content-click="false"
							transition="scale-transition"
							offset-y
							max-width="290px"
							min-width="290px"
						>
							<template v-slot:activator="{ on }">
								<v-text-field
									label="Select Date"
									readonly
									solo
									flat
									outlined
									:disabled="todayDate ? true : false"
									append-icon="mdi-calendar"
									:value="date"
									v-on="on"
									:error-messages="errors.date"
								></v-text-field>
							</template>
							<v-date-picker
								v-model="date"
								:max="nowDate"
								no-title
								@input="fromDateMenu = false"
							></v-date-picker>
						</v-menu>
					</v-col>
				</v-row>

				<v-row class="d-flex align-center flex-row">
					<v-col
						cols="6"
						class="pa-0 d-flex flex-column justify-space-around"
					>
						<v-checkbox
							v-model="anonimity"
							:disabled="givenBy ? true : false"
							label="Anonymous"
							class="ma-0 pa-0"
							color="orange"
						></v-checkbox>
					</v-col>
					<v-col
						cols="6"
						class="py-0 d-flex flex-column justify-space-around"
					>
						<v-checkbox
							v-model="todayDate"
							label="Today"
							class="ma-0 pa-0"
							color="orange darken-3"
							:disabled="date ? true : false"
						></v-checkbox>
					</v-col>
				</v-row>

				<v-col cols="12"
					><p class="ma-0 label">Services:</p>
					<Search
						:initialData="services"
						:url="`/${$store.state.app.projectId}/services/search`"
						@search-result="updateService($event)"
					></Search>
				</v-col>

				<v-text-field
					label="Enter Embedded Youtube Video URL"
					solo
					flat
					outlined
					v-model="videoURL"
					:error-messages="errors.videoURL"
				></v-text-field>

				<v-col class="pt-0" cols="12"
					><p class="ma-0 label">Rating:</p>
					<v-rating
						v-model="starRating"
						background-color="grey lighten-1"
						color="warning"
						hover
					></v-rating>
				</v-col>

				<v-textarea
					solo
					flat
					name="body"
					label="Enter Text/ Description for the testimonial*"
					no-resize
					outlined
					v-model="body"
					@input="$v.body.$touch()"
					@blur="$v.body.$touch()"
					:error-messages="bodyErrors()"
				></v-textarea>
			</v-col>

			<v-divider></v-divider>

			<v-card-actions class="pa-6 card-actions">
				<UnoSaveButton
					@click.native="saveData()"
					@primary-button-click="testimonyListing()"
					primary-button-text="Move To Testimony Listing"
					secondary-button-text="Continue Editing"
					:alert="alert"
					:saving="loading"
				></UnoSaveButton>

				<UnoPublishButton
					v-if="isEdit && $can('publish', 'Testimony')"
					:is-published="isPublished"
					@click.native="toggleStatus()"
				></UnoPublishButton>

				<v-spacer></v-spacer>

				<UnoDeleteButton
					v-if="isEdit && $can('delete', 'Testimony')"
					@confirm="deleteTestimony()"
				></UnoDeleteButton>
			</v-card-actions>
		</v-card>
	</v-dialog>
</template>

<script>
import Search from '@/components/Search'
import { required } from 'vuelidate/lib/validators'
import _ from 'lodash'
// ACTION BUTTONS
import UnoPublishButton from '@/components/Buttons/UnoPublishButton'
import UnoSaveButton from '@/components/Buttons/UnoSaveButton'
import UnoDeleteButton from '@/components/Buttons/UnoDeleteButton'
// Alerts
export default {
	components: {
		UnoSaveButton,
		UnoDeleteButton,
		UnoPublishButton,
		Search,
	},
	data() {
		return {
			loading: false,
			dialog: true,
			nowDate: new Date().toISOString().substr(0, 10),
			showCalender: false,
			fromDateMenu: false,
		}
	},
	validations: {
		body: {
			required,
		},
	},
	watch: {
		givenBy() {
			this.errors.givenBy = ''
		},
		body() {
			this.errors.body = ''
		},
		date() {
			this.errors.date = ''
		},
		todayDate() {
			this.errors.date = ''
		},
		anonimity() {
			this.errors.givenBy = ''
		},
	},
	computed: {
		alert: {
			get() {
				return this.$store.state.testimony.alert
			},
		},
		date: {
			get() {
				return this.$store.state.testimony.date
			},
			set(value) {
				return this.commitTestimonyData({ date: value })
			},
		},
		givenBy: {
			get() {
				return this.$store.state.testimony.givenBy
			},
			set: _.debounce(function (value) {
				this.commitTestimonyData({ givenBy: value })
			}, 500),
		},
		body: {
			get() {
				return this.$store.state.testimony.body
			},
			set: _.debounce(function (value) {
				this.commitTestimonyData({ body: value })
			}, 500),
		},
		anonimity: {
			get() {
				return this.$store.state.testimony.anonimity
			},
			set(value) {
				return this.commitTestimonyData({ anonimity: value })
			},
		},
		todayDate: {
			get() {
				return this.$store.state.testimony.todayDate
			},
			set(value) {
				return this.commitTestimonyData({ todayDate: value })
			},
		},
		errors: {
			get() {
				return this.$store.state.testimony.errors
			},
		},
		haveErrors: {
			get() {
				return this.$store.state.testimony.haveErrors
			},
		},
		isPublished: {
			get() {
				return this.$store.state.testimony.isPublished
			},
		},
		isEdit: {
			get() {
				return this.$store.state.testimony.isEdit
			},
			set(value) {
				return this.commitTestimonyData({ isEdit: value })
			},
		},
		id: {
			get() {
				return this.$store.state.testimony.id
			},
		},
		services: {
			get() {
				return this.$store.state.testimony.services
			},
		},
		videoURL: {
			get() {
				return this.$store.state.testimony.videoURL
			},
			set(value) {
				return this.commitTestimonyData({ videoURL: value })
			},
		},
		starRating: {
			get() {
				return this.$store.state.testimony.starRating
			},
			set(value) {
				return this.commitTestimonyData({ starRating: value })
			},
		},
	},
	methods: {
		bodyErrors() {
			const errors = []
			if (!this.$v.body.$dirty) return errors

			!this.$v.body.required && errors.push('Body is required')
			return errors
		},
		commitTestimonyData: function (testimonyData) {
			this.$store.commit('testimony/updateTestimony', testimonyData)
		},
		async saveData() {
			await this.$store.dispatch('testimony/validateTestimonyData')
			this.$emit('closeModal')
			this.$store.commit('testimony/clearState')
			this.isEdit = false
		},
		deleteTestimony() {
			this.$store.dispatch('testimony/deleteTestimony', {
				testimonyId: this.id,
			})
			this.$emit('closeModal')
		},
		testimonyListing() {
			this.dialog = false
		},
		resetValidation() {
			if (this.haveErrors) {
				this.$store.commit('testimony/clearErrors')
				this.$emit('closeModal')
			} else {
				this.$emit('closeModal')
			}
		},
		async toggleStatus() {
			await this.$store.dispatch('testimony/toggleStatus', {
				id: this.id,
			})
			await this.$store.dispatch('testimony/getListingData', {
				root: true,
			})
		},
		updateService(array) {
			this.$store.commit('testimony/updateServices', array)
		},
	},
}
</script>

<style scoped>
.headline {
	font-weight: 400 !important;
}
.save-btn {
	color: white !important;
}
.trash-btn:hover {
	background-color: #ffebee;
}
.restore-btn:hover {
	background-color: #e8f5e9;
}
.card-actions .icon {
	font-size: 17px;
}
::v-deep .v-icon {
	padding: 3px !important;
}
</style>
