<template>
	<v-row v-if="paginator.lastPage > 1" class="mx-0">
		<v-col cols="9" class="pages">
			<ul class="pagination pa-0">
				<li
					@click="
						paginator.currentPage !== 1
							? debouncedChangePage({ page: 1 })
							: null
					"
					:class="[
						'page',
						paginator.currentPage == 1 ? 'disabled' : '',
					]"
				>
					<a>First</a>
				</li>
				<li
					@click="
						paginator.currentPage !== 1
							? debouncedChangePage({
									page: paginator.currentPage - 1,
							  })
							: null
					"
					v-bind:class="{ disabled: paginator.currentPage === 1 }"
				>
					<span
						class="mdi mdi-arrow-left-bold-circle"
						style="
							font-size: 30px;
							color: var(--blue);
							cursor: pointer;
						"
					>
					</span>
				</li>
				<li
					v-for="(pageNumber, index) in pagesArr"
					:key="index"
					:class="[
						paginator.currentPage === pageNumber ? 'active' : '',
						'page',
					]"
					@click="debouncedChangePage({ page: pageNumber })"
				>
					<a>{{ pageNumber }}</a>
				</li>
				<li
					@click="
						paginator.currentPage !== paginator.lastPage
							? debouncedChangePage({
									page: paginator.currentPage + 1,
							  })
							: null
					"
					v-bind:class="[
						paginator.currentPage === paginator.lastPage
							? 'disabled'
							: '',
					]"
				>
					<span
						class="mdi mdi-arrow-right-bold-circle"
						style="
							font-size: 30px;
							color: var(--blue);
							cursor: pointer;
						"
					>
					</span>
				</li>
				<li
					@click="
						paginator.currentPage !== paginator.lastPage
							? debouncedChangePage({ page: paginator.lastPage })
							: null
					"
					v-bind:class="[
						paginator.currentPage === paginator.lastPage
							? 'disabled'
							: '',
						'page',
					]"
				>
					<a> Last </a>
				</li>
			</ul>
		</v-col>
		<v-col cols="2" class="total">
			Page
			<input
				v-model="currentPage"
				@keyup.enter="debouncedChangePage({ page: currentPage })"
				class="text-center"
			/>
			of {{ paginator.lastPage }}
		</v-col>
	</v-row>
</template>
<script>
import _ from 'lodash'

export default {
	props: {
		paginator: {
			type: Object,
			required: true,
		},
		url: {
			type: String,
			required: true,
		},
		urlParams: {
			type: Object,
			default: function () {
				return {}
			},
		},
	},
	data: function () {
		return {
			currentPage: '',
		}
	},
	created: function () {
		this.currentPage = this.paginator.currentPage
		// Registering debouncers.
		this.debouncedChangePage = _.debounce((val) => {
			this.changePage(val)
		}, 800)
	},
	watch: {
		'paginator.currentPage': function (value) {
			this.currentPage = value
		},
	},
	computed: {
		pagesArr: function () {
			let _current = this.paginator.currentPage
			let _lastPage = this.paginator.lastPage
			let _arr = []
			if (_lastPage > 1) {
				// FIRST ELEMENT'S DATA
				if (_current === 1) {
					_arr.push(1)
				} else {
					if (_current === _lastPage && _lastPage > 2) {
						_arr.push(_current - 2)
					} else {
						_arr.push(_current - 1)
					}
				}

				// SECOND ELEMENT'S DATA
				if (_lastPage <= 2) {
					if (_current === _lastPage && _lastPage === 2) {
						_arr.push(_current)
					} else {
						_arr.push(_current + 1)
					}
				} else if (_lastPage > 2) {
					if (_current === _lastPage) {
						_arr.push(_current - 1)
					} else {
						_arr.push(_arr[0] + 1)
					}
				}

				// THIRD ELEMENT'S DATA
				if (_lastPage >= 3 && _current !== _lastPage) {
					_arr.push(_arr[1] + 1)
				} else if (_lastPage >= 3 && _current === _lastPage) {
					_arr.push(_current)
				}
			}

			return _arr
		},
	},
	methods: {
		changePage: function (paginatorParams) {
			// validation if user put value greater than last page
			if (paginatorParams.page > this.paginator.lastPage) {
				return false
			}
			let allParams = { ...paginatorParams, ...this.urlParams }
			// this.$emit('paginator_filtering')
			this.axios
				.get(this.url, {
					params: allParams,
				})
				.then((res) => {
					this.$emit('update', res.data)
				})
				.catch((err) => console.log(err))
		},
	},
}
</script>
<style scoped>
.row {
	margin: 15px 0px !important;
	display: flex;
	flex-direction: row;
	align-items: baseline;
}
.pagination .page a {
	padding: 5px;
	font-size: 15px;
}
.pagination .page {
	background-color: white;
	color: var(--blue);
	font-weight: 600;
	border: 1px solid #c5cae9;
	display: flex;
	justify-content: space-evenly;
	border-radius: 20px;
	width: 35px;
	cursor: pointer;
}
.pagination .page:first-child,
.pagination .page:last-child {
	width: 10%;
}
.pagination .page:hover {
	border: 1px solid var(--blue);
}
.pagination .page.active a {
	color: white !important;
	padding: 5px;
}
.pagination .page.active {
	background-color: var(--blue);
	width: 35px;
}
.pagination {
	display: flex;
	justify-content: space-evenly;
	align-items: center;
}
.pages {
	width: 46% !important;
}
.pages,
.total {
	padding-right: 0px;
	margin-left: 0px !important;
}
.total {
	font-weight: 600;
}
.total input {
	margin: 0px 0px;
	height: 1rem;
	width: 30%;
	padding: 0px 4px;
	background-color: rgb(213, 234, 255);
}
.total input:not([type]):focus {
	border-bottom: 1px solid var(--blue) !important;
}
.disabled {
	pointer-events: none;
	opacity: 0.6;
}
</style>
