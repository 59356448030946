<template>
	<div>
		<v-tooltip bottom v-if="isPublished">
			<template v-slot:activator="{ on, attrs }">
				<v-chip v-bind="attrs" v-on="on" class="publish-chip">
					Published</v-chip
				></template
			><span class="tooltip-text">Uploaded</span></v-tooltip
		>
		<v-tooltip bottom v-else>
			<template v-slot:activator="{ on, attrs }">
				<v-chip v-bind="attrs" v-on="on" class="unpublish-chip">
					Unpublished</v-chip
				></template
			><span class="tooltip-text">Not Uploaded Yet</span></v-tooltip
		>
	</div>
</template>
<script>
export default {
	props: {
		isPublished: {
			type: Boolean,
			required: true,
		},
	},
}
</script>
<style scoped>
.publish-chip {
	background-color: var(--blue-bg) !important;
	color: #00a63f !important;
	border-radius: 7px !important;
	display: flex !important;
	justify-content: center;
	padding: 17px !important;
}
.unpublish-chip {
	background-color: #ffe0b2 !important;
	color: #bf360c !important;
	border-radius: 7px !important;
	display: flex !important;
	justify-content: center;
	padding: 17px !important;
}
</style>
